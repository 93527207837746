import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GeneralBanner from '../components/generalBanner'
import Meet from '../components/staff/meet'
import Certification from '../components/certification'
import Band from "../components/band"

const Staff = () => (
  <Layout>
    <SEO title="Staff" />
    <GeneralBanner
      title="STAFF"
    />
    <Meet />
    <Certification
      background="#f2f2f2"
    />
    <Band
      bandBackground="#69B02A"
    />
  </Layout>
)

export default Staff