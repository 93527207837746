import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from 'styled-components'
import { Div, P } from '../styled-components'

const Li = styled.li`
  margin: 5px;
  font-size: 14px;
  color: #666;
`;

const Meet = () => {
  const data = useStaticQuery(graphql`
    query {
      jose: file(relativePath: { eq: "jose.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      alexander: file(relativePath: { eq: "alexander.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      john: file(relativePath: { eq: "john.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Div
      padding="40px 10px"
      display="flex"
      flexDirection="column"
      align_items="center"
    >
      <P
        margin="0 0 30px"
        fontSize="32px"
        fontWeight="bold"
        color="#666"
        textAlign="center"
      >
        MEET OUR TEAM
      </P>
      <Div
        width="280px"
        height="2px"
        background_color="#69B02A"
      />
      <P
        margin="30px 0 50px"
        fontSize="18px"
        color="#666"
        textAlign="center"
      >
        We are professionals with extensive experience in testing and balancing projects.
      </P>
      <Div
        display="flex"
        flexDirection1030="column"
      >
        <Div
          display="flex"
          flexDirection="column"
          margin="0 40px 0 0"
        >
          <Div
            width="300px"
            height="340px"
          >
            <Img
              fluid={data.alexander.childImageSharp.fluid}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Div>
          <Div
            width="300px"
            height="460px"
            height1030="230px"
            padding="20px 10px"
            display="flex"
            flexDirection="column"
            align_items="center"
            background_color="#f2f2f2"
          >
            <P
              margin="0 0 20px"
              fontSize="20px"
              fontWeight="bold"
              color="#666"
              textAlign="center"
            >
              Alexander Rendon <br /> PE President - Chief Estimator AABC Certified
            </P>
            <Div
              margin="0 0 12px"
              width="100px"
              height="2px"
              background_color="#69B02A"
            />
            <ul>
              <Li>AABC Test &amp; Balance Certified Engineer.</Li>
              <Li>18 years’ experience in Testing Adjusting & Balancing. (Air & Hydronics).</Li>
            </ul>
          </Div>
        </Div>
        {/* <Div
          margin="0 40px"
          margin1030="30px 0"
          display="flex"
          flexDirection="column"
        >
          <Div
            width="300px"
            height="340px"
          >
            <Img
              fluid={data.john.childImageSharp.fluid}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Div>
          <Div
            width="300px"
            height="460px"
            height1030="230px"
            padding="20px 10px"
            display="flex"
            flexDirection="column"
            align_items="center"
            background_color="#f2f2f2"
          >
            <P
              margin="0 0 20px"
              fontSize="20px"
              fontWeight="bold"
              color="#666"
              textAlign="center"
            >
              John Bryan <br /> Vice-President of Operations AABC Certified
            </P>
            <Div
              margin="0 0 12px"
              width="100px"
              height="2px"
              background_color="#69B02A"
            />
            <ul>
              <Li>AABC Test &amp; Balance Certified Engineer.</Li>
              <Li>19 years’ experience in Testing Adjusting & Balancing. (Air & Hydronics).</Li>
            </ul>
          </Div>
        </Div> */}
        <Div
          display="flex"
          flexDirection="column"
        >
          <Div
            width="300px"
            height="340px"
          >
            <Img
              fluid={data.jose.childImageSharp.fluid}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Div>
          <Div
            width="300px"
            height="460px"
            padding="20px 10px"
            display="flex"
            flexDirection="column"
            align_items="center"
            background_color="#f2f2f2"
          >
            <P
              margin="0 0 20px"
              fontSize="20px"
              fontWeight="bold"
              color="#666"
              textAlign="center"
            >
              José Luis Casillas <br /> Vice-President <br /> HVAC Engineer
            </P>
            <Div
              margin="0 0 12px"
              width="100px"
              height="2px"
              background_color="#69B02A"
            />
            <ul>
              <Li>AABC Test &amp; Balance Certified Engineer.</Li>
              <Li>NEBB Certified Professional #23668.</Li>
              <Li>BSME from Polytechnic University of Puerto Rico.</Li>
              <Li>Professional Engineer License #18917.</Li>
              <Li>National Environmental Balancing Bureau (NEBB) Certified Professional.</Li>
              <Li>Member of the CIAPR (Colegio de Ingenieros y Agrimensores de Puerto Rico).</Li>
              <Li>18 years’ experience in the Pharmaceutical and Medical Device Manufacturing Industry.</Li>
              <Li>Member of the American Society of Heating, Refrigerating and Air Conditioning Engineers (ASHRAE).</Li>
            </ul>
          </Div>
        </Div>
      </Div>
    </Div>
  )
}

export default Meet